<div class="flex justify-end">
  <div class="grid grid-cols-1 gap-4">
    <div class="bubble-wrapper bg-black-4" (click)="goToTop()">
      <!-- TODO: change the icon -->
      <span class="material-icons-outlined icon-wrapper">
        vertical_align_top
      </span>
    </div>
    <div class="bubble-wrapper bg-primary">
      <!-- TODO: change the icon -->

      <span class="material-icons-outlined icon-wrapper">
        chat_bubble_outline
      </span>
    </div>
  </div>
</div>
