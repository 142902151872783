export class RouteConstant {
  // HOME
  public static readonly HOME: string = 'home';

  // Invest at IGM
  public static readonly INVEST: string = 'invest';
  // Platform Features
  public static readonly GLOBAL_CONNECTION: string = 'global-connection';
  public static readonly HYBRID_MODEL: string = 'hybrid-model';
  // Fintech Tools
  public static readonly IGM_MOBILE_APP: string = 'igm-mobile-app';
  public static readonly TRADING_PLATFORM: string = 'trading-platform';
  // Account Type
  public static readonly ACCOUNT_TYPES: string = 'account-types';
  public static readonly INDIVIDUAL: string = 'individual-wrap';
  public static readonly SELF_SERVED: string = 'self-served';
  // public static readonly BENEFICIARY: string = 'beneficiary';
  public static readonly CORPORATE: string = 'corporate-wrap';
  public static readonly DISCRETIONARY: string = 'discretionary';
  // Cash Solution
  public static readonly CASH_SOLUTION: string = 'cash-solution';
  public static readonly MULTI_CURRENCY_CASH_ACCOUNT: string =
    'multi-currency-cash-account';
  public static readonly INTEREST_RATE: string = 'interest-rate';
  public static readonly FX: string = 'fx';
  public static readonly MAKING_PAYMENT: string = 'making-payment';
  public static readonly AUTO_SWEEP: string = 'auto-sweep';
  public static readonly PRODUCT_FINANCING: string = 'product-financing';

  // Product
  public static readonly PRODUCTS: string = 'products';
  public static readonly FUNDS: string = 'funds';
  public static readonly BONDS: string = 'bonds';
  public static readonly STOCKS: string = 'stocks';
  public static readonly ETFS: string = 'etfs';
  public static readonly MANAGE_PORTFOLIO: string = 'managed-portfolio';
  public static readonly INSURANCE: string = 'insurance';

  // Our advisers
  public static readonly OUR_ADVISERS: string = 'our-advisers';
  public static readonly PROFILE: string = 'profile';
  public static readonly MEET_THE_TEAM: string = 'meet-the-team';
  public static readonly JOIN_US: string = 'join-us';


  // Insight
  public static readonly INSIGHTS: string = 'insights';
  public static readonly ARTICLES: string = 'articles';
  public static readonly VIDEOS: string = 'videos';

  // About Us
  public static readonly ABOUT_US: string = 'about-us';

  // Contact Us
  public static readonly CONTACT_US: string = 'contact-us';

  // Career
  public static readonly CAREER: string = 'career';

  // ========== Footer =============
  public static readonly DISCLAIMERS_AND_STATEMENT: string =
    'disclaimers-and-statement';
  public static readonly DISCLAIMERS: string = 'disclaimers';
  public static readonly PRIVACY_STATEMENT: string = 'privacy-statement';
  public static readonly SP_DISCLAIMERS: string = 'sp-disclaimers';
  public static readonly CYBER_SECURITY: string = 'cyber-security';
  public static readonly PERSONAL_INFO_COLLECTION_STATEMENT: string =
    'personal-information-collection-statement';
  public static readonly HKEX_DISCLAIMER: string = 'hkex-disclaimer';
  public static readonly MORNINGSTAR_DISCLAIMER: string =
    'morningstar-disclaimer';
  public static readonly TRADINGVIEW_DISCLAIMER: string = 'tradingview-disclaimer';
}
