<!-- Mobile -->
<div class="" [ngClass]="{ 'mobile-menu-wrapper': isMenuBarOpen }">
  <div class="mt-2 xl:mt-0 flex">
    <i
      class="igm igm-menu-8 text-xl cursor-pointer"
      (click)="menuBarController()"
    ></i>
    <img
      [src]="environment.imagePath + '/igm-logo.svg'"
      class="mx-6 cursor-pointer"
      (click)="goToPageByUrl('home')"
    />
  </div>

  <nz-drawer
    [nzClosable]="false"
    [nzVisible]="isMenuBarOpen"
    nzPlacement="left"
    (nzOnClose)="isMenuBarOpen = false"
    [nzSize]="'default'"
  >
    <ng-container *nzDrawerContent>
      <div class="divider-wrapper">
        <div class="p-8 py-4">
          <div class="flex justify-between">
            <img
              [src]="environment.imagePath + '/igm-logo.svg'"
              class="cursor-pointer"
              (click)="goToPageByUrl('home')"
            />

            <i
              class="igm igm-e-remove text-2xl cursor-pointer"
              (click)="isMenuBarOpen = false"
            ></i>
          </div>
        </div>
      </div>

      <ng-container *ngIf="!selectedCategory">
        <div class="p-8 pb-0">
          <div
            class="cursor-pointer text-black-6-6 text-sm"
            (click)="goToPageByUrl('home')"
          >
            {{ "menu.home.page" | translate }}
          </div>
          <ng-container *ngFor="let item of menuList; index as i">
            <div
              class="cursor-pointer text-black-6-6 my-8 text-sm"
              *ngIf="!item.children"
            >
              <span (click)="goToPageByUrl(item.url)">
                {{ item.name | translate }}
              </span>
            </div>

            <div
              class="cursor-pointer text-black-6-6 my-8 text-sm flex justify-between"
              *ngIf="item.children"
              (click)="openCategory(item.name)"
            >
              <span>
                {{ item.name | translate }}
              </span>

              <i class="igm igm-right-arrow relative top-0.5"></i>
            </div>
          </ng-container>
        </div>

        <nz-divider></nz-divider>

        <div class="p-8 py-0">
          <div class="text-black-6-6 my-8 text-sm flex justify-between">
            <div>{{ "menu.language" | translate }}</div>
            <div
              class="cursor-pointer"
              (click)="selectedCategory = 'menu.language'"
            >
              <span class="mx-2">
                {{ getCurrentLang() }}
              </span>
              <i class="igm igm-right-arrow relative top-0.5"></i>
            </div>
          </div>

          <div class="text-black-6-6 my-8 text-sm flex justify-between mb-12">
            <div class="relative top-3">{{ "menu.region" | translate }}</div>
            <div
              class="flex cursor-pointer"
              (click)="selectedCategory = 'menu.region'"
            >
              <img
                [src]="environment.imagePath + '/common/HKD.svg'"
                class="h-[1.5rem]"
              /><span class="mx-2 mt-1">{{
                "menu.country.region.hk" | translate
              }}</span>

              <i class="igm igm-right-arrow relative top-1"></i>
            </div>
          </div>

          <button
            nz-button
            class="ant-btn-outline w-full my-4"
            (click)="openSignInModal()"
          >
            {{ "menu.sign.in" | translate }}
          </button>
          <button
            nz-button
            class="ant-btn-primary w-full"
            (click)="openGetAppModal()"
          >
            {{ "menu.get.the.app" | translate }}
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="selectedCategory">
        <div class="p-8 pb-0">
          <div class="flex cursor-pointer" (click)="selectedCategory = ''">
            <i class="igm igm-left-arrow-1 text-black-6-6 font-bold"></i>
            <span class="text-sm text-black-6-6 font-bold mx-2">{{
              selectedCategory | translate
            }}</span>
          </div>

          <ng-container
            *ngIf="
              selectedCategory !== 'menu.region' &&
              selectedCategory !== 'menu.language'
            "
          >
            <ng-container
              *ngFor="
                let subPage of menuList[currentSelectCategory()].children;
                index as i
              "
            >
              <div
                *ngIf="subPage.url"
                class="text-sm text-black-6-6 my-8 cursor-pointer"
                (click)="
                  goToPageByUrl(subPage.url, selectedCategory.toLowerCase())
                "
              >
                {{ subPage.name | translate }}
              </div>

              <div
                class="my-8 cursor-pointer flex justify-between"
                *ngIf="!subPage.url"
                (click)="selectedPageController(i)"
              >
                <span class="text-sm text-primary font-bold">
                  {{ subPage.name | translate }}
                </span>

                <i class="igm igm-right-arrow relative top-0.5"></i>
              </div>

              <ng-container *ngIf="subPage.isSelected">
                <ng-container *ngFor="let page of subPage.subPage">
                  <div class="px-8 mb-6">
                    <div
                      class="text-sm text-black-6-6 cursor-pointer"
                      (click)="
                        goToPageByUrl(
                          page.url,
                          i === 2
                            ? 'accountTypes'
                            : i === 3
                            ? 'cashSolution'
                            : 'investAtIgm'
                        )
                      "
                    >
                      {{ page.name | translate }}

                      <span class="tag-wrapper" *ngIf="page.tag">{{
                        page.tag | translate
                      }}</span>
                    </div>

                    <div class="text-sm text-black-5 my-2">
                      {{ page.des | translate }}
                    </div>

                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="selectedCategory === 'menu.language'">
            <ng-container *ngFor="let lang of langList; index as i">
              <div
                class="country-list-wrapper flex justify-between mt-4 cursor-pointer"
                (click)="changeLang(lang.value)"
                [ngClass]="{ 'bg-[#f6f9fc]': lang.value === currentLang }"
              >
                <div>
                  {{ lang.label }}
                </div>

                <div>
                  <i
                    class="igm igm-check-single-1 text-primary mx-1 relative top-1"
                    *ngIf="lang.value === currentLang"
                  ></i>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="selectedCategory === 'menu.region'">
            <ng-container *ngFor="let c of countryList; index as i">
              <div
                class="country-list-wrapper cursor-pointer flex justify-between"
                (click)="goToPageByUrl(c.url, 'igmWebsite')"
                [ngClass]="{ 'mt-4': i === 0, 'bg-[#f6f9fc]': i === 1 }"
              >
                <div class="flex">
                  <img
                    [src]="c.imgPath"
                    [alt]="c.countryRegion | translate"
                    class="h-[1.5rem]"
                  />
                  <span class="mx-1">
                    {{ c.countryRegion | translate }}
                  </span>
                </div>

                <i
                  class="igm igm-check-single-1 text-primary mx-1 relative top-1"
                  *ngIf="i === 1"
                ></i>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </nz-drawer>
</div>
