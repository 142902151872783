import { registerLocaleData } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteConstant } from '@constant/route.constant';
import { TranslateService } from '@ngx-translate/core';
import { NzI18nService, en_US, zh_CN, zh_TW } from 'ng-zorro-antd/i18n';
import localeEn from '@angular/common/locales/en';
import localeCh from '@angular/common/locales/zh-Hant';
import localeZh from '@angular/common/locales/zh-Hans';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AppModalComponent } from '../app-modal/app-modal.component';
import { SignInModalComponent } from '../sign-in-modal/sign-in-modal.component';
import { environment } from '@env';

interface MenuList {
  name: string;
  dropdownMenu?: string;
  url: string;
  children?: any[];
  isOpening?: boolean;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
})
export class HeaderComponent {
  protected readonly environment = environment;

  screenWidth: number;

  menuList: MenuList[] = [
    {
      name: 'menu.invest.at.igm',
      dropdownMenu: 'menu',
      url: '',
      children: [
        {
          name: 'menu.platform.features',
          url: '',
          isSelected: false,
          subPage: [
            {
              name: 'menu.hybrid',
              url: RouteConstant.HYBRID_MODEL,
              des: 'menu.hybrid.des',
            },
            {
              name: 'menu.global.connections',
              url: RouteConstant.GLOBAL_CONNECTION,
              des: 'menu.global.connection.des',
            },
          ],
        },
        {
          name: 'menu.fintech.tools',
          url: '',
          isSelected: false,
          subPage: [
            {
              name: 'menu.igm.app',
              url: RouteConstant.IGM_MOBILE_APP,
              des: 'menu.igm.app.des',
            },
            {
              name: 'menu.trading.platform',
              url: RouteConstant.TRADING_PLATFORM,
              des: 'menu.trading.platform.des',
            },
          ],
        },
        {
          name: 'menu.account.types',
          url: '',
          isSelected: false,
          subPage: [
            // { name: 'Advisory', url: RouteConstant.ADVISORY,  },
            {
              name: 'menu.self.served',
              url: RouteConstant.SELF_SERVED,
              des: 'menu.self.served.des',
            },
            {
              name: 'menu.indi.wrap',
              url: RouteConstant.INDIVIDUAL,
              des: 'menu.indi.wrap.des',
            },
            {
              name: 'menu.corp.wrap',
              url: RouteConstant.CORPORATE,
              des: 'menu.corp.wrap.des',
            },
            {
              name: 'menu.discretionary',
              url: RouteConstant.DISCRETIONARY,
              des: 'menu.discretionary.des',
            },
          ],
        },
        {
          name: 'menu.cash.solution',
          url: '',
          isSelected: false,
          subPage: [
            {
              name: 'menu.multi.currency.cash.acct',
              url: RouteConstant.MULTI_CURRENCY_CASH_ACCOUNT,
            },
            {
              name: 'menu.interest.rate',
              url: RouteConstant.INTEREST_RATE,
            },
            { name: 'menu.fx', url: RouteConstant.FX },
            { name: 'menu.making.payment', url: RouteConstant.MAKING_PAYMENT },
            {
              name: 'menu.auto.sweep',
              url: RouteConstant.AUTO_SWEEP,
              tag: 'menu.new',
            },
            {
              name: 'menu.product.financing',
              url: RouteConstant.PRODUCT_FINANCING,
              tag: 'menu.new',
            },
          ],
        },
      ],
      isOpening: false,
    },
    {
      name: 'menu.products',
      dropdownMenu: 'productsMenu',
      url: '',
      children: [
        { name: 'menu.funds', url: RouteConstant.FUNDS },
        { name: 'menu.bonds', url: RouteConstant.BONDS },
        { name: 'menu.stocks', url: RouteConstant.STOCKS },
        { name: 'menu.etfs', url: RouteConstant.ETFS },
        { name: 'menu.dpms', url: RouteConstant.MANAGE_PORTFOLIO },
        { name: 'menu.insurance', url: RouteConstant.INSURANCE },
      ],
      isOpening: false,
    },
    {
      name: 'menu.our.advisers',
      dropdownMenu: 'adviserMenu',
      url: '',
      children: [
        { name: 'menu.meet.the.team', url: RouteConstant.MEET_THE_TEAM },
        { name: 'menu.join.us', url: RouteConstant.JOIN_US },
      ],
      isOpening: false,
    },
    {
      name: 'menu.insights',
      dropdownMenu: 'insightsMenu',
      url: '',
      children: [
        { name: 'menu.articles', url: RouteConstant.ARTICLES },
        { name: 'menu.videos', url: RouteConstant.VIDEOS },
      ],
      isOpening: false,
    },
    { name: 'menu.about.igm', url: RouteConstant.ABOUT_US },
    { name: 'menu.contact.us', url: RouteConstant.CONTACT_US },
  ];

  langList: any[] = [
    { label: 'English', value: 'en' },
    { label: '简体中文', value: 'zh' },
    { label: '繁體中文', value: 'ch' },
  ];

  countryList: any[] = [
    {
      countryRegion: 'menu.country.region.sg',
      url: 'https://www.ifastgm.com.sg/igm/home/main',
      imgPath: environment.imagePath + '/home-page/region-icon/SGD.svg',
    },
    {
      countryRegion: 'menu.country.region.hk',
      url: '',
      imgPath: environment.imagePath + '/home-page/region-icon/HKD.svg',
    },
    {
      countryRegion: 'menu.country.region.my',
      url: 'https://www.ifastgm.com.my/myifast/',
      imgPath: environment.imagePath + '/home-page/region-icon/MYR.svg',
    },
  ];

  currentLang: string;

  isMenuBarOpen = false;

  selectedCategory = '';

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private i18n: NzI18nService,
    private nzModalServices: NzModalService,
    private activatedRoute: ActivatedRoute
  ) {
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      const lang = params.get('lang') || 'en';
      this.currentLang = lang;
      this.translateService.use(lang);
      localStorage.setItem('locale', lang);

      if (lang === 'en') {
        registerLocaleData(localeEn, 'en');
        this.i18n.setLocale(en_US);
      } else if (lang === 'ch') {
        registerLocaleData(localeCh, 'zh');
        this.i18n.setLocale(zh_TW);
      } else if (lang === 'zh') {
        registerLocaleData(localeZh, 'ch');
        this.i18n.setLocale(zh_CN);
      }
    });
  }

  goToPageByUrl(url: string, category?: string): void {
    if (!url) {
      return;
    }

    if (category === 'menu.our.advisers') {
      category = 'our-advisers';
    }

    if (category?.includes('menu.')) {
      category = category.split('.')[1];
    }

    this.trackNavigationClick(url, category);

    this.isMenuBarOpen = false;
    this.selectedCategory = '';
    this.selectedPageController(1, true);

    const lang = localStorage.getItem('locale') || 'en';

    switch (category) {
      case 'products':
        this.router.navigate(['/' + lang + '/' + RouteConstant.PRODUCTS + '/' + url]);
        break;

      case 'investAtIgm':
        this.router.navigate(['/' + lang + '/' + RouteConstant.INVEST + '/' + url]);
        break;

      case 'accountTypes':
        this.router.navigate([
          '/' + lang + '/' +
            RouteConstant.INVEST +
            '/' +
            RouteConstant.ACCOUNT_TYPES +
            '/' +
            url,
        ]);

        break;

      case 'cashSolution':
        this.router.navigate([
          '/' + lang + '/' +
            RouteConstant.INVEST +
            '/' +
            RouteConstant.CASH_SOLUTION +
            '/' +
            url,
        ]);

        break;

      case 'our-advisers':
        this.router.navigate(['/' + lang + '/' + RouteConstant.OUR_ADVISERS + '/' + url]);
        break;

      case 'insights':
        this.router.navigate(['/' + lang + '/' + RouteConstant.INSIGHTS + '/' + url]);
        break;

      case 'igmWebsite':
        window.open(url);
        break;

      default:
        this.router.navigate(['/' + lang + '/' + url]);
    }
  }

  trackNavigationClick(url: string, category: string | undefined): void {
    (<any>window).dataLayer = (<any>window).dataLayer || [];
    (<any>window).dataLayer.push({
      event: 'navigation_click',
      category: category,
      url: url
    });
  }

  changeLang(lang: string): void {
    this.translateService.use(lang).subscribe(() => {
      this.currentLang = lang;

      let url = this.router.url;
      const oldLang = localStorage.getItem('locale') || 'en';

      if (url.startsWith(`/${oldLang}/`)) {
        url = url.replace(`/${oldLang}/`, `/${lang}/`);
      } else {
        url = `/${lang}${url.startsWith('/') ? '' : '/'}${url}`;
      }

      localStorage.setItem('locale', lang);
  
      if ('en' === this.currentLang) {
        registerLocaleData(localeEn, 'en');
        this.i18n.setLocale(en_US);
      } else if ('ch' === this.currentLang) {
        registerLocaleData(localeCh, 'zh');
        this.i18n.setLocale(zh_TW);
      } else if ('zh' === this.currentLang) {
        registerLocaleData(localeZh, 'ch');
        this.i18n.setLocale(zh_CN);
      }
      
      this.router.navigateByUrl(url);
    });
  }

  getCurrentLang(): string {
    const index = this.langList.findIndex((v) => v.value === this.currentLang);
    return this.langList[index].label;
  }

  menuBarController(): void {
    this.isMenuBarOpen = !this.isMenuBarOpen;

    if (!this.isMenuBarOpen) {
      this.selectedCategory = '';
    }
  }

  openCategory(name: string): void {
    this.selectedCategory = name;
  }

  currentSelectCategory(): number {
    return this.menuList.findIndex((v) => v.name === this.selectedCategory);
  }

  selectedPageController(index: number, clearAll?: boolean): void {
    this.menuList[0].children?.forEach((v, listIndex) => {
      v.isSelected = false;

      if (listIndex === index && !clearAll) {
        v.isSelected = true;
      }
    });
  }

  openGetAppModal(): void {
    this.nzModalServices.create({
      nzContent: AppModalComponent,
      nzWidth: '400px',
      nzFooter: null,
      nzMaskClosable: false,
      nzKeyboard: false,
    });
  }

  openSignInModal(): void {
    this.nzModalServices.create({
      nzContent: SignInModalComponent,
      nzWidth: '800px',
      nzFooter: null,
      nzMaskClosable: false,
      nzKeyboard: false,
    });
  }

  changeVisible(category: string, action: boolean): void {
    const index = this.menuList.findIndex((v) => v.name === category);
    this.menuList[index].isOpening = action;
  }

  @HostListener('window:resize', ['$event'])
  onResize( ) {
    this.screenWidth = window.innerWidth;
  }

  isScreenSizeWithinRange(): boolean {
    return this.screenWidth >= 1200 && this.screenWidth <= 1439;
  }
}
