export const environment = {
  production: true,
  serverUrl: "/igm-hk",
  ifastTVUrl: "https://ifasttv.com/ifast-tv-now-showing-embed-external",
  videoPath: "https://www.ifastgm.com.hk/igm-hk/videos",
  imagePath: "https://www.ifastgm.com.hk/igm-hk/images",
  filePath: "https://www.ifastgm.com.hk/igm-hk/files",
  rrmsUrl: "https://careers.ifastcorp.com",
  hkB2bUrl: "https://secure.ifastfinancial.com.hk"
};
