import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiEditButtonComponent } from './ui-edit-button/ui-edit-button.component';
import { UiBinButtonComponent } from './ui-bin-button/ui-bin-button.component';
import { UiFormButtonComponent } from './ui-form-button/ui-form-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { PageBubbleComponent } from './page-bubble/page-bubble.component';

const DECLARATIONS = [
  UiEditButtonComponent,
  UiBinButtonComponent,
  UiFormButtonComponent,
  PageBubbleComponent,
];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [CommonModule, TranslateModule, NzButtonModule],
  exports: [...DECLARATIONS],
})
export class UiButtonModule {}
