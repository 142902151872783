import { Component } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'app-app-modal',
  templateUrl: './app-modal.component.html',
  styleUrls: ['./app-modal.component.less']
})
export class AppModalComponent {
  protected readonly environment = environment;
}
