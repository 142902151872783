import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { HeaderComponent } from './components/header/header.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { DatePipe } from '@angular/common';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { BrowserModule } from '@angular/platform-browser';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { LayoutComponent } from './layout/layout.component';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './components/footer/footer.component';
import { AppModalComponent } from './components/app-modal/app-modal.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { SignInModalComponent } from './components/sign-in-modal/sign-in-modal.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';

@NgModule({
  declarations: [HeaderComponent, LayoutComponent, FooterComponent, AppModalComponent, SignInModalComponent, MobileMenuComponent],
  imports: [
    RouterModule,
    NzMenuModule,
    NzLayoutModule,
    NzAvatarModule,
    NzDrawerModule,
    NzDropDownModule,
    BrowserModule,
    NzButtonModule,
    NzDropDownModule,
    NzDividerModule,
    TranslateModule,
    NzModalModule
  ],
  providers: [DatePipe],
  exports: [HeaderComponent]
})
export class CoreModule {}
