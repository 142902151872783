<!-- Desktop -->
<div
  class="hidden xl:flex fixed z-[100] top-0 bg-white w-screen px-8 2xl:py-4 py-2"
>
  <div class="w-full max-w-full 2xl:max-w-screen-xl mx-auto border-box flex justify-between w-full my-1">
    <!-- Menu -->
    <div>
      <div class="flex">
        <img
          [src]="environment.imagePath + '/igm-logo.svg'"
          class="mr-2 cursor-pointer"
          (click)="goToPageByUrl('home')"
        />

        <ul nz-menu nzMode="horizontal">
          <ng-container *ngFor="let category of menuList; index as i">
            <li class="dropdown-menu" nz-menu-item *ngIf="category.children">
              <span
                nz-dropdown
                [nzDropdownMenu]="i === 0 ? menu : otherMenu"
                (nzVisibleChange)="changeVisible(category.name, $event)"
              >
                <span [ngClass]="{ 'text-primary': category.isOpening }">
                  {{ category.name | translate }}
                </span>
                <span
                  class="material-icons-outlined relative top-1"
                  [ngClass]="{ 'text-primary': category.isOpening }"
                >
                  {{
                    category.isOpening
                      ? "keyboard_arrow_up"
                      : "keyboard_arrow_down"
                  }}
                </span>
              </span>
            </li>

            <li
              class="dropdown-menu"
              nz-menu-item
              *ngIf="!category.children"
              (click)="goToPageByUrl(category.url)"
            >
              <span class="menu-title">
                {{ category.name | translate }}
              </span>
            </li>

            <nz-dropdown-menu #menu="nzDropdownMenu">
              <div class="w-full max-w-full 2xl:max-w-screen-xl mx-auto border-box grid grid-cols-4 gap-4 dropdown-box invest-box">
                <ng-container
                  *ngFor="let c of category.children; index as cIndex"
                >
                  <div>
                    <h6 class="dropdown-menu-header flex">
                      <span class="text-info font-bold mx-1">
                        {{ c.name | translate }}</span
                      >
                    </h6>

                    <ng-container *ngFor="let page of c.subPage">
                      <div class="flex">
                      <div
                        class="dropdown-submenu"
                        (click)="
                          goToPageByUrl(
                            page.url,
                            cIndex === 2
                              ? 'accountTypes'
                              : cIndex === 3
                              ? 'cashSolution'
                              : 'investAtIgm'
                          )
                        "
                        *ngIf="!page.subPageHeader"
                        [ngClass]="{ 'mb-1': page.des }"
                      >
                        {{ page.name | translate }}

                        <i class="igm igm-arrow-e_filled relative top-0.5"></i>

                        
                      </div>
                      <span class="tag-wrapper mx-1" *ngIf="page.tag">{{
                        page.tag | translate
                      }}</span>

                      </div>
                      <p class="text-black-5 text-xs menu-des mb-4" *ngIf="page.des">
                        {{ page.des | translate }}
                      </p>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </nz-dropdown-menu>

            <nz-dropdown-menu #otherMenu="nzDropdownMenu">
              <div class="dropdown-box min-w-[15rem]">
                <ng-container *ngFor="let children of category.children">
                  <div
                    class="dropdown-submenu"
                    (click)="
                      goToPageByUrl(
                        children.url,
                        i === 1
                          ? 'products'
                          : i === 2
                          ? 'our-advisers'
                          : 'insights'
                      )
                    "
                  >
                    {{ children.name | translate }}
                    <i class="igm igm-arrow-e_filled relative top-0.5"></i>
                  </div>
                </ng-container>
              </div>
            </nz-dropdown-menu>
          </ng-container>
        </ul>
      </div>
    </div>

    <!-- Mobile Menu -->
    <div class="block xl:hidden 2xl:hidden">
      <app-mobile-menu></app-mobile-menu>
    </div>

    <!-- Login Btn -->
    <div class="flex justify-between my-auto">
      <div class="relative login-btn">
        <button
          nz-button
          class="ant-btn ant-btn-outline"
          nzSize="small"
          [class.ant-btn-sm]="isScreenSizeWithinRange()"
          (click)="openSignInModal()"
        >
          {{ "menu.sign.in" | translate }}
        </button>
        <button
          nz-button
          class="ant-btn ant-btn-primary mx-2"
          nzType="primary"
          nzSize="small"
          [class.ant-btn-sm]="isScreenSizeWithinRange()"
          (click)="openGetAppModal()"
        >
          {{ "menu.get.the.app" | translate }}
        </button>
      </div>

      <div class="relative xl:top-2 lg:top-1 flex text-black-6 flex-nowrap">
        <div nz-dropdown [nzDropdownMenu]="translationMenu">
          <span class="material-icons-outlined language-icon"> language </span>
        </div>
        <nz-divider [nzType]="'vertical'" class="h-[1.5em]"></nz-divider>
        <div nz-dropdown [nzDropdownMenu]="regionMenu" class="flex">
          <img
            [src]="environment.imagePath + '/common/HKD.svg'"
            class="h-[1.5rem]"
          /><span class="mx-2 text-sm mt-1">{{ "menu.country.region.hk" | translate }}</span>
        </div>
      </div>

      <nz-dropdown-menu #regionMenu="nzDropdownMenu">
        <div class="country-dropdown-box">
          <ng-container *ngFor="let c of countryList; index as i">
            <div
              class="country-list-wrapper flex justify-between"
              [ngClass]="{ 'bg-[#f6f9fc]': i === 1 }"
              (click)="goToPageByUrl(c.url, 'igmWebsite')"
            >
              <div class="flex">
                <img
                  [src]="c.imgPath"
                  [alt]="c.countryRegion | translate"
                  class="h-[1.5rem]"
                />
                <span class="mx-1">
                  {{ c.countryRegion | translate }}
                </span>
              </div>

              <i
                class="igm igm-check-single-1 text-primary mx-1 relative top-1"
                *ngIf="i === 1"
              ></i>
            </div>
          </ng-container>
        </div>
      </nz-dropdown-menu>

      <nz-dropdown-menu #translationMenu="nzDropdownMenu">
        <div class="translation-dropdown-box">
          <ng-container *ngFor="let lang of langList; index as i">
            <div
              class="country-list-wrapper flex justify-between"
              (click)="changeLang(lang.value)"
              [ngClass]="{ 'bg-[#f6f9fc]': lang.value === currentLang }"
            >
              <div>
                {{ lang.label }}
              </div>

              <div>
                <i
                  class="igm igm-check-single-1 text-primary mx-1 relative top-1"
                  *ngIf="lang.value === currentLang"
                ></i>
              </div>
            </div>
          </ng-container>
        </div>
      </nz-dropdown-menu>
    </div>
  </div>
</div>

<!-- Mobile -->
<div class="block xl:hidden 2xl:hidden fixed z-[100] top-0 bg-white w-screen h-12 px-4 md:px-8">
  <app-mobile-menu></app-mobile-menu>
</div>
