import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-bin-button',
  templateUrl: './ui-bin-button.component.html',
  styleUrls: ['./ui-bin-button.component.less'],
})
export class UiBinButtonComponent {
  @Input() nzFloat = 'right';
  @Input() nzLight = false;
  @Input() nzDisabled = false;
  @Output() nzClick: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
