import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteConstant } from '@constant/route.constant';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  title = 'hk-b2b-igm-web';

  constructor(
    private router: Router,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      if (
        !window.location.href.includes(RouteConstant.ACCOUNT_TYPES) &&
        !window.location.href.includes(RouteConstant.CASH_SOLUTION)
      ) {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 10);
      }
    });

    this.translateService.setDefaultLang(
      localStorage.getItem('locale') || 'en'
    );
    this.translateService.use(localStorage.getItem('locale') || 'en');
  }
}
