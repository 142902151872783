<app-header></app-header>

<div class="print:mt-0 mt-12 2xl:mt-20">
  <router-outlet></router-outlet>
</div>

<div class="scroll-top-bubble z-[200]" (click)="scrollToTop()">
  <i class="igm igm-move-layer-up-1_filled"></i>
</div>

<app-footer></app-footer>
