import { Component, Input } from '@angular/core';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.less'],
})
export class MobileMenuComponent extends HeaderComponent {
  @Input() nzClass = '';

  ngOnInt(): void {
    if(!this.nzClass) {
      this.nzClass = ''
    }
  }
}
