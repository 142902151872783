import { Component } from '@angular/core';

@Component({
  selector: 'ui-page-bubble',
  templateUrl: './page-bubble.component.html',
  styleUrls: ['./page-bubble.component.less'],
})
export class PageBubbleComponent {
  goToTop(): void {
    window.scrollTo(0, 0);
  }
}
