import { Component } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'app-sign-in-modal',
  templateUrl: './sign-in-modal.component.html',
  styleUrls: ['./sign-in-modal.component.less'],
})
export class SignInModalComponent {
  protected readonly environment = environment;

  platformList = [
    {
      imgPath: environment.imagePath + '/header/igp-logo.svg',
      alt: 'iFAST Global Prestige',
      url: environment.hkB2bUrl + '/b2b/login;platform=igp',
    },
    {
      imgPath: environment.imagePath + '/header/ifast-central-logo.svg',
      alt: 'iFAST Central',
      url: environment.hkB2bUrl + '/b2b/login;platform=ifa',
    },
  ];

  openWindow(url: string): void {
    window.open(url);
  }
}
