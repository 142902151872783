import { APP_INITIALIZER } from "@angular/core";
import { ApiService } from "./api.service";

export function initConfig(apiService: ApiService) {
  return () => apiService.loadConfig();
}

export const AppConfigInitializerProvider = {
  provide: APP_INITIALIZER,
  useFactory: initConfig,
  deps: [ApiService],
  multi: true,
};
