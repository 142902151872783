<div class="text-center py-16">
  <p class="text-md text-black-7">
    {{ "menu.please.select.a.platform" | translate }}
  </p>

  <div class="grid md:grid-cols-2 gap-4 mt-4">
    <div class="box-wrapper" *ngFor="let item of platformList">
      <div class="flex justify-center" (click)="openWindow(item.url)">
        <img [src]="item.imgPath" [alt]="item.alt" />
      </div>
    </div>
  </div>
</div>
