<div class="text-right">
  <button
    nz-button
    class="ant-btn-grey"
    (click)="backBtnClick.emit()"
    nzSize="small"
  >
    {{ "common.back" | translate }}
  </button>

  <button
    nz-button
    class="ant-btn-primary ml-4"
    (click)="nextBtnClick.emit()"
    nzSize="small"
  >
    {{
      (!isLastStep
        ? "common.next"
        : isNewCase
        ? "common.save"
        : "common.update"
      ) | translate
    }}
  </button>
</div>
