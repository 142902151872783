import { Injectable } from '@angular/core';
import {HttpClient, HttpContext, HttpHeaders, HttpParams} from '@angular/common/http';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonSc } from '@interface/common/SearchCritera/common-sc.interface';
import { SearchResult } from '@interface/common/SearchCritera/search-result.interface';
import { FindByScRequest } from '@interface/common/SearchCritera/find-by-sc-request.interface';
import { environment } from "@env";

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  public apiUrl: string | null = null;

  constructor(private http: HttpClient) {
  }

  public loadConfig() {
    this.apiUrl = environment.serverUrl;
  }

  post<T>(path: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T> {
    return this.http.post<T>(this.apiUrl + path, body, options).pipe(
      take(1)
    );
  }

  get<T>(path: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }) {
    return this.http.get<T>(this.apiUrl + path, options).pipe(take(1));
  }

  put<T>(path: string, body: any | null): Observable<T> {
    return this.http.put<T>(this.apiUrl + path, body).pipe(
      take(1)
    );
  }

  delete<T>(path: string): Observable<T> {
    return this.http.delete<T>(this.apiUrl + path).pipe(
      take(1)
    );
  }

  // for getting a page
  findPageBySc<T, SC>(
    path: string,
    sc?: SC,
    commonSc?: CommonSc
  ): Observable<SearchResult<T>> {
    const request: FindByScRequest<SC> = {
      sc: sc,
      commonSc: commonSc,
    };
    if (!commonSc) {
      request.commonSc = {
        page: 1,
        size: 10,
      } as CommonSc;
    }
    return this.post<SearchResult<T>>(path, request);
  }

  // for getting a list
  findBySc<T, SC>(path: string, sc?: SC): Observable<T[]> {
    const request: FindByScRequest<SC> = {
      sc: sc,
    };
    return this.post<T[]>(path, request);
  }

  postURLEncodedData<T>(
    url: string,
    body: URLSearchParams | null
  ): Observable<T> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const options = { headers: headers };

    return this.http.post<T>(url, body, options).pipe(take(1));
  }
}
