import { CommonModule } from '@angular/common';
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-edit-button',
  templateUrl: './ui-edit-button.component.html',
  styleUrls: ['./ui-edit-button.component.less'],
})
export class UiEditButtonComponent implements OnInit {
  @Input() nzFloat = 'right';
  @Input() nzLight = false;
  @Output() nzClick: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
