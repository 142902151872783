<a
  class="float-right"
  [ngClass]="[
    nzDisabled
      ? 'ui-bin-disabled'
      : nzLight
      ? 'ui-bin-button'
      : 'ui-bin-button-light',
    nzFloat === 'right' ? 'float-right' : 'float-left'
  ]"
  (click)="nzClick.emit()"
>
  <span class="material-icons text-2xl p-0.5">delete</span>
</a>
