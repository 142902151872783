import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ThemeService } from 'src/shared-lib/service/theme.service';
import { AppConfigInitializerProvider } from 'src/shared-lib/service/app-config-initializer.service';
import { AppInitializerProvider } from 'src/shared-lib/service/theme-initializer.service';
import { CoreModule } from '@core/core.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UiButtonModule } from 'src/shared-lib/ui-components/ui-components/button/button.module';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LocaleInterceptor } from "./routes/locale.interceptor";
import { LottieModule } from 'ngx-lottie';

registerLocaleData(en);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function playerFactory() {
  return import('lottie-web');
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    UiButtonModule,
    LottieModule.forRoot({ player: playerFactory})
  ],
  providers: [
    ThemeService,
    AppConfigInitializerProvider,
    AppInitializerProvider,
    { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: LocaleInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
