import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TranslateService } from "@ngx-translate/core";

@Injectable(

)
export class LocaleInterceptor implements HttpInterceptor {
    constructor(
        private translateService: TranslateService
    ) {}

    isShowTokenExpired = false;

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: {
                locale: this.translateService.currentLang || 'en'
            }
        });
        return next.handle(request).pipe(
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }
}
