import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-form-button',
  templateUrl: './ui-form-button.component.html',
  styleUrls: ['./ui-form-button.component.less'],
})
export class UiFormButtonComponent {
  @Input() isLastStep: boolean = false;
  @Input() isNewCase: boolean = false;
  @Output() nextBtnClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() backBtnClick: EventEmitter<void> = new EventEmitter<void>();
}
