<div class="relative">
  <!-- <div class="absolute top-[-2.5rem]"> -->
  <div class="absolute top-[-0.5rem] md:top-[-4.5rem] bg-[#0c2848] md:bg-[transparent]">
    <div class="relative top-[2rem]">
      <img
        [src]="environment.imagePath + '/footer/wave.svg'"
        class="hidden md:block w-screen min-h-[5rem]"
      />
    </div>

    <div class="px-4 md:px-8 xl:px-16 py-8 lg:pb-12 bg-[#0c2848]">
      <div class="w-full max-w-full 2xl:max-w-screen-xl mx-auto border-box grid lg:grid-cols-3 gap-4 lg:gap-16 justify-stretch">
        <div class="text-[#FFFFFFDE]">
          <img [src]="environment.imagePath + '/footer/igm-logo-white.svg'" />

          <p class="text-sm my-4">
            {{ "footer.des" | translate }}
          </p>

          <div class="flex gap-2">
            <ng-container *ngFor="let item of socialMediaList">
              <img
                [src]="item.imgPath"
                [alt]="item.des"
                (click)="openWindow(item.url)"
                class="cursor-pointer"
              />
            </ng-container>
          </div>
        </div>

        <div class="text-[#FFFFFFDE]">
          <div class="info-item">
            <i class="igm igm-mail relative top-1"></i>
            <span>service@ifastgm.com.hk</span>
          </div>

          <nz-divider class="white-divider"></nz-divider>

          <div class="info-item">
            <i class="igm igm-b-meeting-1 relative top-1"></i>
            <span>+852 3766 4334</span>
          </div>

          <nz-divider class="white-divider"></nz-divider>

          <div class="info-item">
            <i class="igm igm-map-pin relative top-1"></i>
            <span [innerHTML]="'contact.us.office.address' | translate"></span>
          </div>
          <nz-divider class="white-divider"></nz-divider>
        </div>

        <div class="lg:relative">
          <div class="lg:absolute lg:right-0">
            <p class="text-sm text-[#FFFFFFDE]">
              {{ "footer.install.app" | translate }}
            </p>

            <div class="flex gap-4 mt-2 lg:justify-end">
              <img
                [src]="
                  environment.imagePath + '/home-page/platform/qr-code.svg'
                "
                alt="QR Code"
                class="w-[150px] h-[150px]"
              />

              <div>
                <ng-container *ngFor="let icon of downloadIconList">
                  <img
                    [src]="getIcon(icon.type)"
                    [alt]="icon.imageDes"
                    class="cursor-pointer w-[150px] min-h-[45px] mb-2"
                    (click)="clickedDownloadBtn(icon.type)"
                  />
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-[#08203B] px-4 md:px-8 xl:px-16 py-4">
      <div class="w-full max-w-full 2xl:max-w-screen-xl mx-auto border-box">
        <div class="flex flex-wrap justify-center lg:justify-start gap-4">
          <ng-container *ngFor="let item of footerPage">
            <div
              class="text-[#FFFFFFDE] cursor-pointer"
              (click)="routeToPage(item.url)"
            >
              {{ item.name | translate }}
            </div>
          </ng-container>
        </div>

        <div
          class="text-black-3 text-xs my-4"
          [innerHTML]="'footer.investment.risk' | translate"
        ></div>

        <div class="text-[#FFFFFFDE] text-sm">
          {{ "footer.copyright" | translate }} {{ currentYear }}
          {{ "footer.copyright.sent.1" | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
