import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSerializer, DefaultUrlSerializer, UrlTree } from '@angular/router';
import { RouteConstant } from '@constant/route.constant';
import { LayoutComponent } from '@core/layout/layout.component';
// import { AuthGuard } from '@core/guard/auth.guard';

class CustomUrlSerializer extends DefaultUrlSerializer {
  override parse(url: string): UrlTree {
    const singleSegmentPaths = [
      RouteConstant.HOME, 
      RouteConstant.ABOUT_US, 
      RouteConstant.CONTACT_US
    ];
    
    const segments = url.split('/').filter(segment => segment);
    if (segments.length === 1 && singleSegmentPaths.includes(segments[0])) {
      return super.parse(`/en/${segments[0]}`);
    }
    
    return super.parse(url);
  }
}

const routes: Routes = [
  {
    path: '',
    redirectTo: `/en/${RouteConstant.HOME}`,
    pathMatch: 'full',
  },
  {
    path: ':lang',
    component: LayoutComponent,
    children: [
      {
        path: RouteConstant.HOME,
        loadChildren: () =>
          import('./modules/home-page/home-page.module').then(
            (m) => m.HomePageModule
          ),
      },
      {
        path: RouteConstant.INVEST,
        loadChildren: () =>
          import('./modules/invest-at-igm/invest-at-igm.module').then(
            (m) => m.InvestAtIgmModule
          ),
      },
      {
        path: RouteConstant.PRODUCTS,
        loadChildren: () =>
          import('./modules/products/products.module').then(
            (m) => m.ProductsModule
          ),
      },
      {
        path: RouteConstant.OUR_ADVISERS,
        loadChildren: () =>
          import('./modules/our-advisers/our-advisers.module').then(
            (m) => m.OurAdvisersModule
          ),
      },
      {
        path: RouteConstant.INSIGHTS,
        loadChildren: () =>
          import('./modules/insights/insights.module').then(
            (m) => m.InsightsModule
          ),
      },
      {
        path: RouteConstant.ABOUT_US,
        loadChildren: () =>
          import('./modules/about-us/about-us.module').then(
            (m) => m.AboutUsModule
          ),
      },
      {
        path: RouteConstant.CONTACT_US,
        loadChildren: () =>
          import('./modules/contact-us/contact-us.module').then(
            (m) => m.ContactUsModule
          ),
      },
      {
        path: RouteConstant.DISCLAIMERS_AND_STATEMENT,
        loadChildren: () =>
          import('./modules/disclaimers-and-statement/disclaimers-and-statement.module').then(
            (m) => m.DisclaimersAndStatementModule
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: `/en/${RouteConstant.HOME}`,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{ provide: UrlSerializer, useClass: CustomUrlSerializer }],
})
export class AppRoutingModule {}
