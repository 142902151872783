<a
  class="float-right"
  [ngClass]="[
    nzLight ? 'ui-edit-button' : 'ui-edit-button-light',
    nzFloat === 'right' ? 'float-right' : 'float-left'
  ]"
  (click)="nzClick.emit()"
>
  <span class="material-icons text-2xl p-0.5">edit</span>
</a>
